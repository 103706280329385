import React from 'react'
import './header.css'
import CTA from './CTA'
import LEAF from '../../assets/heart_blur_edges.jpg'
import HeaderSocials from './HeaderSocials'


const Header = () => {
  return (
    <header >
      <div className="container header__container" >
        <h1 className='heading-primary--main'>SKINNOVATE</h1>
        <h5 className="text-light heading-primary--sub">Clinical Skin Studio</h5>
        <CTA />
        <HeaderSocials />
        <div className="leaf">
          <img src={LEAF} alt="leaf-pic" />
        </div> 
        <a href="#contact" className='scroll__down'>Scroll Down</a>
      </div>
          
    </header>
  )
}

export default Header