import React from 'react'
import {BsGoogle} from 'react-icons/bs'
import {FaFacebook, FaInstagram, FaYelp} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.google.com/search?q=google+reviews+skinnovate+washington&rlz=1C1CHBF_enUS881US888&sxsrf=APq-WBsqc-w76ZGJrVnExtC9MI_5GXa9ug%3A1645571838491&ei=_m4VYsW1HajBkPIP37GX2Aw&ved=0ahUKEwjFrIrnuJT2AhWoIEQIHd_YBcsQ4dUDCA4&uact=5&oq=google+reviews+skinnovate+washington&gs_lcp=Cgdnd3Mtd2l6EAMyBQghEKABMgUIIRCgATIFCCEQoAE6BwgAEEcQsAM6CggAEEcQsAMQyQM6BwgjEOoCECc6BAgjECc6EAguELEDEIMBEMcBENEDEEM6CggAELEDEIMBEEM6BQgAEJECOgsIABCxAxCDARCRAjoHCAAQsQMQQzoQCAAQgAQQhwIQsQMQgwEQFDoICAAQgAQQsQM6CggAEIAEEIcCEBQ6BQgAEIAEOg0IABCABBCHAhCxAxAUOgsIABCABBCxAxCDAToICAAQgAQQyQM6BggAEBYQHjoJCAAQFhAeEIsDOgUIIRCrAkoECEEYAEoECEYYAFC1I1jNVGDnVWgDcAF4AIABogGIAZsjkgEENi4zMJgBAKABAbABCsgBA7gBAsABAQ&sclient=gws-wiz#lrd=0x54906c85af09815f:0x76d92232eb962cde,1,,," target="__blank"><BsGoogle/></a>
        <a href="https://www.yelp.com/biz/skinnovate-bellevue?osq=skinnovate" target="__blank"><FaYelp/></a>
        <a href="https://www.facebook.com/Skinnovate/"target="__blank"><FaFacebook/></a>
        <a href="https://www.instagram.com/Skinnovate/?hl=en"target="__blank"><FaInstagram/></a>
    </div>
  )
}

export default HeaderSocials