import React from 'react'
import './experience.css'

const Experience = () => {
  return (
    <section id='welcome'>
      <h5>Welcome</h5>
      <h2>Bellevue's Latest In Advanced Laser Procedures</h2>

      <div className="container experience__container">
        <p>Come experience the most advanced technology in various laser procedures. Skinnovate is the premier place to experience highly effective laser hair removal, photo rejuvenation, photofacials, and pigment reduction. </p>
        <p>Have you been contemplating laser hair removal for a while now?  You are making the best choice. It’s life-changing!  It’s a simple and quick procedure with no downtime and minimal discomfort.  As long as your body hair is dark in color, you are a good candidate.  My laser can treat any skin tone from very light to very dark and every tone in between. A consultation can be given over the phone or in person and are always complimentary.</p>
        <p>As a pioneer in the laser industry, owner and licensed master esthetician, Shelly Arensberg will take ultimate care of you.  She’s been performing laser procedures for 20 years and knows her stuff. It’s all she does all day long, only laser! She’s an expert in the laser field.  You’ll feel comfortable and well cared for throughout the whole process.</p>
      </div>
      <div></div>
    </section>
  )
}

export default Experience