import React from 'react'
import './nav.css'
import { useState } from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      {/* <a href="#" onClick={()=> setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}>Home</a> */}
      <a href="#welcome" onClick={()=> setActiveNav('#welcome')} className={activeNav === '#welcome' ? 'active' : ''}>Welcome</a>
      <a href="#treatments" onClick={()=> setActiveNav('#treatments')} className={activeNav === '#treatments' ? 'active' : ''}>Services</a>
      <a href="#about" onClick={()=> setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>About</a>
      <a href="#learn" onClick={()=> setActiveNav('#learn')} className={activeNav === '#kearn' ? 'active' : ''}>Learn</a>
      {/* <a href="#portfolio" onClick={()=> setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><CgWebsite/></a> */}
      <a href="#contact" onClick={()=> setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>Contact</a>
    </nav>
  )
}

export default Nav