import React from 'react'
import './services.css'
import LASER from '../../assets/Laser_200.jpg'
import PHOTO from '../../assets/Photo_200.jpg'
import PHOTO6 from '../../assets/Photo-6.jpg'
import PHOTO7 from '../../assets/Photo-7.jpg'
import PHOTO8 from '../../assets/Photo-8.jpg'
import PHOTO9 from '../../assets/Photo-9.jpg'
import PHOTO2 from '../../assets/Photo-2.jpg'
import PHOTO4 from '../../assets/Photo-4.jpg'


const Services = () => {
  return (
    <section id='learn'>
      <h5>More Information</h5>
      <h2>Learn More About My Services</h2>
      <div className="container services__container">
        <article className="service">

          <div className="service__head">
            <h3>Laser Hair Removal</h3>
          </div>
          <div className="service__list">
            <img src={PHOTO6} alt="Photo6" />
            <p className='service_p'>Laser hair removal is a noninvasive procedure that uses a laser – pulses of light that destroy the hair follicle – to significantly reduce unwanted hair with minimal discomfort. This process is recognized as one of the most efficient and permanent methods of eliminating facial or body hair. </p>
            <img src={PHOTO2} alt="Photo2" />
            <p>The laser is most effective in removing hair that is brown in color. Multiple treatments are required approximately 6-12 weeks apart, depending on the treatment area. The most common areas treated are the face, underarms, bikini, legs, or back and shoulders for men. We offer laser services from head to toe, except for male privates.</p>
            <img src={PHOTO4} alt="Photo4" />
            <p className='service_p'>The hair must be shaved before treatment and free from recent sun exposure or self-tanner. Skinnovate uses the Cynosure Icon™, one of the most advanced and versatile lasers. This IPL (Intense Pulsed Light) device safely and effectively treats all skin tones and types, even the most sensitive of skin.</p>
            <img src={LASER} alt="Laser" />

          </div>
        </article>
        {/* End of Laser */}

        <article className="service">

          <div id="learnIPL" className="service__head">
            <h3>IPL Photo Rejuvenation</h3>
          </div>
          <div className="service__list">
            <img src={PHOTO} alt="IPL" />
            <p className='service_p'>
              Photo Rejuvenation (also known as a photofacial) is a method to combat skin damage in a softer, gentler way than many other laser procedures. The Cynosure Icon™ offers advanced IPL (Intense Pulsed Light) and fluorescent pulsed light systems, utilizing the latest innovations in non-ablative cosmetic skin rejuvenation.
            </p>
            <p className='service_p'>
              We use our advanced light systems to restore a more youthful appearance to your skin by reducing the appearance of redness, flushing, hyperpigmentation (brown spots), sun spots, broken capillaries, fine lines, and overall skin damage.  This noninvasive treatment will provide you with smoother, healthier, younger-looking skin.
            </p>
            <img src={PHOTO8} alt="Photo8" />
            <p className='service_p'>
              Photo Rejuvenation systems deliver pulses of intense light, through very specific filters, to the skin. Once delivered, the targeted tissue absorbs the light’s energy. Depending on the area and your skin condition, a series of 2-3 treatments are usually required. Treatments typically take between 30-60 minutes.  The benefits of Photo Rejuvenation include no major downtime, smoother and healthier-looking skin, a more even skin tone, reduced redness and flushing, and an overall boosted appearance to the skin.
            </p>
            <img src={PHOTO9} alt="Photo9" />

            <p className='service_p'>
              Soon after each Photo Rejuvenation session, you will experience some skin tone and texture improvement. There will be a decrease in the overall redness and flushing. Sunspots will fade; pore sizes will shrink. Fine lines and wrinkles will gradually decrease in size as the new collagen forms beneath the skin. These improvements will be more noticeable with each treatment, although the most dramatic reduction in brown pigmentation is typically seen after the first treatment and less so after each additional.
            </p>
            <img src={PHOTO7} alt="Photo7" />
          </div>
        </article>
        {/* End of IPL Photo Rejuvenation */}
      </div>

    </section>
  )
}

export default Services