import React from 'react'
import './treatments.css'


const Treatments = () => {
  return (
    <section id='treatments'>
      <h5>Treatments</h5>
      <h2>Explore Our Services</h2>

      <div className="container treatments__container">
        <div className="treatments__laser">
          <h3>Laser Hair Removal</h3>
         
          <div className="treatments__book">
            <a href="#learn" className='btn'>Learn More</a>
            <a href="#contact" className='btn btn-primary'>Book Now</a>
          </div>
        </div>

        <div className="treatments__ipl_photo">
          <h3>IPL Photo Rejuvenation</h3>
         
          <div className="treatments__book">
            <a href="#learnIPL" className='btn'>Learn More</a>
            <a href="#contact" className='btn btn-primary'>Book Now</a>
          </div>
        </div>

        {/* <div id="promotions" className="treatments__promotions">
          <h2>Please Call or Text</h2>
          <h2>For Pricing</h2>
          <p>We Have Gift Certificates Available</p>
        </div> */}
      </div>
    </section>
  )
}

export default Treatments