import React from 'react'
import './about.css'
import SHELLY from '../../assets/ShellyNewHeadshot2.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {FiHeart} from 'react-icons/fi'



const About = () => {
  return (
    <section id="about">
      <h5>Licensed Master Esthetician & Certified Laser Technician</h5>
      <h2>About Shelly</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={SHELLY} alt="About"  />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
              <article className="about__card">
                <FaAward className='about__icon' />
                <h5>Experienced</h5>
                <small>28+ Years In Skincare</small>
              </article>

              <article className="about__card">
                <FiUsers className='about__icon' />
                <h5>Trusted</h5>
                <small>Certified Laser Technician</small>
              </article>

              <article className="about__card">
                <FiHeart className='about__icon' />
                <h5>Reviewed</h5>
                <small>Find me on <br></br> <span><a href="https://www.yelp.com/biz/skinnovate-bellevue?osq=skinnovate">Yelp </a></span>  |  <span><a href="https://www.google.com/search?q=google+reviews+skinnovate+washington&rlz=1C1CHBF_enUS881US888&sxsrf=APq-WBsqc-w76ZGJrVnExtC9MI_5GXa9ug%3A1645571838491&ei=_m4VYsW1HajBkPIP37GX2Aw&ved=0ahUKEwjFrIrnuJT2AhWoIEQIHd_YBcsQ4dUDCA4&uact=5&oq=google+reviews+skinnovate+washington&gs_lcp=Cgdnd3Mtd2l6EAMyBQghEKABMgUIIRCgATIFCCEQoAE6BwgAEEcQsAM6CggAEEcQsAMQyQM6BwgjEOoCECc6BAgjECc6EAguELEDEIMBEMcBENEDEEM6CggAELEDEIMBEEM6BQgAEJECOgsIABCxAxCDARCRAjoHCAAQsQMQQzoQCAAQgAQQhwIQsQMQgwEQFDoICAAQgAQQsQM6CggAEIAEEIcCEBQ6BQgAEIAEOg0IABCABBCHAhCxAxAUOgsIABCABBCxAxCDAToICAAQgAQQyQM6BggAEBYQHjoJCAAQFhAeEIsDOgUIIRCrAkoECEEYAEoECEYYAFC1I1jNVGDnVWgDcAF4AIABogGIAZsjkgEENi4zMJgBAKABAbABCsgBA7gBAsABAQ&sclient=gws-wiz#lrd=0x54906c85af09815f:0x76d92232eb962cde,1,,,">Google</a></span></small>
              </article>
          </div>
            
          <p> Shelly Arensberg has worked in the skincare industry for over 28 years. After receiving her Bachelor’s Degree from the University of Washington in the year 2000, Shelly acquired her esthetician license from the Euro Institute of Skincare in 2001, where she graduated at the top of her class. Shelly’s background in medical esthetics has helped her master the skills in performing medical grade facials, clinical extractions, chemical peels, and various skin laser treatments, along with developing a strong clinical understanding of skin care. In 2003, Shelly became a Certified Laser Technician and since then has acquired over 19 years of experience in laser procedures such as laser hair removal, photo rejuvenation, fractionated resurfacing and tattoo removal.</p>
          <p> In 2012, after spending over 10 years as an esthetician, Shelly decided to pursue her ultimate dream of starting her own business. Skinnovate is a special place where you will receive the highest quality treatment. Shelly prides herself on her knowledge and expertise, efficiency in communication, thorough customer service and follow-up and an overall extreme passion for skincare and helping people look and feel their best. Shelly keeps updated with new skin care research, has an extensive knowledge of numerous medical grade skin care products, and understands how individual ingredients affect the skin. Shelly has the confident ability to rid you of your unwanted body hair and you’ll be so happy you made the decision to treat!</p>
          <a href="#contact" className="btn btn-primary">Book Now</a>
        </div>
      </div>
    </section>
  )
}

export default About