import React from 'react'
import './contact.css'
import { AiOutlineMail } from 'react-icons/ai'
import { AiOutlinePhone } from 'react-icons/ai'
import { AiOutlineFieldTime } from 'react-icons/ai'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { useRef } from 'react'
import emailjs from 'emailjs-com'
import { BsGoogle } from 'react-icons/bs'
import { FaFacebook, FaInstagram, FaYelp } from 'react-icons/fa'



const Contact = () => {

  const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   emailjs.sendForm('service_stx4p8c', 'template_i7lfolo', form.current, 'TZOnWW4wuNeaUZ9e6')

  //   e.target.reset()
  // };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">

          <article className='contact__option'>
            <AiOutlinePhone className='contact__option-icon' />
            <h4>Call or Text</h4>
            <h5>206-321-5987</h5>
          </article>

          <article className='contact__option'>
            <AiOutlineMail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>shelly@skinnovate.com</h5>
            <a href="mailto:shelly@skinnovate.com">Send a Message</a>
          </article>

          <article className='contact__option'>
            <HiOutlineOfficeBuilding className='contact__option-icon' />
            <h4>Location</h4>
            <h5>1291 131st Ct NE Bellevue, WA 98005</h5>
            <a href="https://www.google.com/maps/dir/33.52109,-112.0595602/google+reviews+skinnovate+washington/@40.2219774,-125.77966,5z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x54906c85af09815f:0x76d92232eb962cde!2m2!1d-122.165097!2d47.6216387" target="__blank">Get Directions</a>
          </article>

        </div>
        <div>
          <article className='hours'>
            <div className='hours__header'>
              <AiOutlineFieldTime className='contact__option-icon' />
              <h4>Hours</h4>
            </div>
            <h5>Monday - Thursday: 12pm - 7pm</h5>
            <h5>Friday: 12pm - 6pm</h5>
            <h5>Saturday: 1pm - 6pm</h5>
            <h5>Sunday: By Request</h5>
            <br />
            <p>Appointments outside of these times may be available, please contact us for special requests.</p>
            <br />
            <p>Please call or text for pricing. We have gift certificates available!</p>
            <br />
            <p>Skinnovate has a 24-hour policy to change or cancel an appointment.  If circumstances cause you to have to cancel or reschedule the day of your appointment, that is entirely understandable. However, you will be charged the full amount of the service. (Ladies: with the exception of your cycle coming unexpectedly, you will not be penalized if you had a bikini service that you’ll need to change) </p>
          </article>
          <div className='contact__socials'>
            <a href="https://www.google.com/search?q=google+reviews+skinnovate+washington&rlz=1C1CHBF_enUS881US888&sxsrf=APq-WBsqc-w76ZGJrVnExtC9MI_5GXa9ug%3A1645571838491&ei=_m4VYsW1HajBkPIP37GX2Aw&ved=0ahUKEwjFrIrnuJT2AhWoIEQIHd_YBcsQ4dUDCA4&uact=5&oq=google+reviews+skinnovate+washington&gs_lcp=Cgdnd3Mtd2l6EAMyBQghEKABMgUIIRCgATIFCCEQoAE6BwgAEEcQsAM6CggAEEcQsAMQyQM6BwgjEOoCECc6BAgjECc6EAguELEDEIMBEMcBENEDEEM6CggAELEDEIMBEEM6BQgAEJECOgsIABCxAxCDARCRAjoHCAAQsQMQQzoQCAAQgAQQhwIQsQMQgwEQFDoICAAQgAQQsQM6CggAEIAEEIcCEBQ6BQgAEIAEOg0IABCABBCHAhCxAxAUOgsIABCABBCxAxCDAToICAAQgAQQyQM6BggAEBYQHjoJCAAQFhAeEIsDOgUIIRCrAkoECEEYAEoECEYYAFC1I1jNVGDnVWgDcAF4AIABogGIAZsjkgEENi4zMJgBAKABAbABCsgBA7gBAsABAQ&sclient=gws-wiz#lrd=0x54906c85af09815f:0x76d92232eb962cde,1,,," target="__blank"><BsGoogle /></a>
            <a href="https://www.yelp.com/biz/skinnovate-bellevue?osq=skinnovate" target="__blank"><FaYelp /></a>
            <a href="https://www.facebook.com/Skinnovate/" target="__blank"><FaFacebook /></a>
            <a href="https://www.instagram.com/Skinnovate/?hl=en" target="__blank"><FaInstagram /></a>
          </div>
        </div>


        {/* <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Your Full Name" required />
          <input type="email name="email placeholder="Your Email" required/>
          <textarea name="message" rows="7" placeholder="Your Message" required></textarea>
          <button type="submit" className='btn btn-primary'>Send Message</button>
            </form> */}
      </div>
    </section>
  )
}

export default Contact